<template>
  <div class="flex justify-between border-b border-text_color">
    <StoresFilterSearch class="flex-auto mb-3" />

    <div class="flex">
      <EStoreDialouge width="auto">
        <template #activator="{ props }">
          <div v-bind="props">
            <div class="relative">
              <v-icon
                icon="mdi mdi-filter-outline"
                :size="20"
                class="text-e_store-light cursor-pointer"
              />

              <div v-if="hasFilter" class="absolute -top-3 left-3">
                <v-icon
                  icon="mdi-close-circle"
                  :size="12"
                  class="text-red-500"
                />
              </div>
            </div>
          </div>
        </template>
        <template #content="{ close }">
          <StoresFilterDialougeFilterContent
            class="sm:min-w-[640px]"
            :store-type-id="storeTypeId"
            :categories="categories"
            :query-params="fetchParams"
            @close=";[close(), $event ? emit('update') : null]"
          />
        </template>
      </EStoreDialouge>

      <div class="mx-2"></div>

      <EStoreDialouge width="auto">
        <template #activator="{ props }">
          <div v-bind="props">
            <div class="relative">
              <v-icon
                icon="mdi mdi-sort-reverse-variant rotate-180"
                :size="20"
                class="text-e_store-light cursor-pointer"
              />

              <div v-if="hasSort" class="absolute -top-3 left-3">
                <v-icon
                  icon="mdi-close-circle"
                  :size="12"
                  class="text-red-500"
                />
              </div>
            </div>
          </div>
        </template>
        <template #content="{ close }">
          <StoresFilterDialougeFilterSortContent
            class="sm:min-w-[640px]"
            :categories="categories"
            :store-type-id="storeTypeId"
            :query-params="fetchParams"
            @close=";[close(), $event ? emit('update') : null]"
          />
        </template>
      </EStoreDialouge>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Category } from '~~/composables/useMenuModel'
withDefaults(
  defineProps<{
    fetchParams?: object
    categories: Category[] | null
    storeTypeId?: number | string
  }>(),
  {
    fetchParams: () => ({}),
    storeTypeId: undefined
  }
)

const emit = defineEmits(['update'])
const route = useRoute()

const hasFilter = computed(() => {
  return (
    route.query.category_id ||
    route.query.name ||
    route.query.is_featured ||
    route.query.offersOnly || route.query.definition_id
  )
})

const hasSort = computed(() => {
  return (
    route.query.orderBy ||
    route.query.orderType ||
    route.query.order_by_view_number ||
    route.query.most_rated ||
    route.query.order_by_sell_count ||
    route.query.min_price ||
    route.query.order_by_latest ||
    route.query.max_price
  )
})
</script>
