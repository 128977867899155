<template>
  <StoresFilterCategoriesParentsFilterMultiLevel
    :categories="categories"
    :enable-navigate="enableNavigate"
    @go-route="emit('go-route', $event)"
  >
    <template
      #categoriesParent="{
        goToCategory,
        isActiveCategory,
        selectedIndex,
        categoriesCategoryStyle
      }"
    >
      <EStoreSwiper
        :items="categoriesCategoryStyle"
        :title="t('categories')"
        auto-slides
        swiper-navigation
        :selected-slide="selectedIndex"
      >
        <template #slide="{ item, index }">
          <button
            :key="`store-filter-order-${item.name}`"
            class="text-xs mx-1 mt-3"
            @click="goToCategory(index)"
          >
            <div class="w-12 xs:w-16 sm:w-20 md:28 lg:w-32 mx-auto">
              <NuxtImg
                style="border-radius: 20px"
                provider="cacheIpx"
                preset="modified"
                loading="lazy"
                :src="item.image"
                :alt="item.name"
                width="128"
                height="128"
                class="mx-auto aspect-square object-cover border"
              />
              <div
                class="h-1 w-8/12 mx-auto"
                :class="{
                  'border-b-2  border-nav_color ': isActiveCategory(index)
                }"
              ></div>
            </div>

            <div
              class="py-1 pb-1 w-14 xs:w-18 sm:w-24 md:32 lg:w-36"
              :class="[
                {
                  'text-text_alt_color': isActiveCategory(index)
                }
              ]"
            >
              <ClientOnly>{{ item.name }}</ClientOnly>
            </div>
          </button>
        </template>
      </EStoreSwiper>
    </template>
  </StoresFilterCategoriesParentsFilterMultiLevel>
</template>

<script setup lang="ts">
import type { Category } from '~~/composables/useMenuModel'

const emit = defineEmits(['go-route'])
const { t } = useI18n()

withDefaults(
  defineProps<{
    categories: Category[]
    enableNavigate?: boolean
    routing?: { method: string; name?: string }
  }>(),
  {
    enableNavigate: false,
    routing: () => ({ method: 'replace' })
  }
)
</script>
