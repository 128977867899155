<template>
  <div v-if="categoriesCategoryStyle?.length" class="e_store-container mt-2 sm:mt-5">
    <RikazCollection
      v-if="!categoryStyle"
      ref="collectionRef"
      class-content="relative"
      :enable-actions-leave-mouse="false"
    >
      <template #trigger>
        <slot
          name="categoriesParent"
          :categories-category-style="categoriesCategoryStyle"
          :go-to-category="goToCategory"
          :is-active-category="isActiveCategory"
          :selected-index="selectedIndex"
        />
      </template>

      <template #content="{ index }">
        <div
          v-if="index!=null&& isActiveCategory(index) && categoriesCategoryStyle[index]?.children?.length"
        >
          <StoresFilterCategoriesChildren
            :key="keyCategoriesChildren"
            :with-out-query-navigation="withOutQueryNavigation"
            :category-id-parent="categoriesCategoryStyle[index].id"
            :first-child="true"
            :categories="categoriesCategoryStyle[index].children!"
            :enable-navigate="enableNavigate"
            @go-catgory-id="emit('go-catgory-id', $event)"
            @go-route="emit('go-route', $event)"
          />
        </div>
      </template>
    </RikazCollection>
    <div v-else>
      <slot
        name="categoriesParent"
        :categories-category-style="categoriesCategoryStyle"
        :go-to-category="goToCategory"
        :is-active-category="isActiveCategory"
        :selected-index="selectedIndex"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Category } from '~~/composables/useMenuModel'
import { useCategoriesCrumbsStore } from '~/store/categories_crumbs'
const { addCrumb, path: pathCrumps } = useCategoriesCrumbsStore()
const collectionRef = ref<HTMLElement | null>(null)
const selectedIndex = ref(-1)
const emit = defineEmits(['go-route', 'go-catgory-id'])
const route = useRoute()

const keyCategoriesChildren = ref(Math.random())
const props = withDefaults(
  defineProps<{
    categories: Category[]
    enableNavigate?: boolean
    withOutQueryNavigation?: boolean
  }>(),
  {
    enableNavigate: false,
    withOutQueryNavigation: false
  }
)
/* لاستخدام البينة الشجرية أو التصنيفات بنفس السطر */
const categoryStyle = computed(() => {
  return useCategoryStyle()
})
/* في حال البنية الشجرية استخدام التصنيفات التي ليس لها أبناء أو ابناء التصنيفات من المستوى الاول فقط */
const categoriesCategoryStyle = computed(() => {
  if (categoryStyle.value) {
    return props.categories?.reduce((total, category: Category) => {
      if (category.children_count === 0) {
        total.push(category)
      } else { total.push(...(category.children ?? [])) }
      return total
    }, [] as Category[])
  } else {
    return props.categories
  }
})

function isActiveCategory(index: number) {
  return selectedIndex.value === index
}

function goToCategory(index: number) {
  /* في حال تم استخدام البنية السطرية للتصانيف او انه ليس هناك أولاد */
  const categoryId = categoriesCategoryStyle.value[index]?.id
  if (props.enableNavigate && (categoriesCategoryStyle.value[index]?.children?.length === 0 || categoryStyle.value)) {
    emit('go-route', categoryId)
  } else {
    /* في حال تم استخدام البنية الشجرية للتصانيف تفعيل التصنيف الثاني */
    if (!categoryStyle.value) {
      if (collectionRef.value != null) {
        collectionRef.value.setIndex(index)
      }
    }

    if (selectedIndex.value === index) {
      /* في حال تم اختيار نفس التصنيف المختار سابقا سيتم الغاء التحديد وتحديث الكويري */
      QueryNavigation(undefined)
      selectedIndex.value = -1
    } else {
      QueryNavigation(categoryId)
      selectedIndex.value = index
      /* إضافة التصنيفات الجديدة لتوليد مسار التصنيفات */
      addCrumb(categoriesCategoryStyle.value[index])
      categoriesCategoryStyle.value[index].children?.forEach((el) => {
        addCrumb(el)
      })
      keyCategoriesChildren.value = Math.random()
    }
  }
}
function QueryNavigation(categoryId?: number) {
  if (props.withOutQueryNavigation) {
    emit('go-catgory-id', categoryId)
  } else {
    useQueryNavigation({ category_id: categoryId })
  }
}
onMounted(() => {
  toggleQueryCategoryID()
})

function toggleQueryCategoryID() {
  const indexCategoryInParents = ref(-1)
  if (route.query.category_id) {
    /* في حال تم اختيار البنية الشجرية */
    if (!categoryStyle.value) {
      if (collectionRef.value) {
        /* إيجاد اندكس تصنيف الاب */
        indexCategoryInParents.value = categoriesCategoryStyle.value?.findIndex(
          (el) => el.id.toString() === route.query.category_id
        )
        /* ترسيت اندكس تصنيف الابن */
        const indexCategoryInChildren = ref(-1)
        /* في حال عدم وجود التصنيف المختار في التصنيفات الاباء - البحث ضمن المستوى الاول للابناء */
        /* لانه عند توليد الشجرة يتم استخدام التصنيفات التي ليس لها أبناء أو ابناء التصنيفات من المستوى الاول فقط */
        if (indexCategoryInParents.value < 0) {
          categoriesCategoryStyle.value?.forEach((categoryParent, index) => {
            const indexCategoryInChildrenTemp = categoryParent?.children?.findIndex(
              (el) => el.id.toString() === route.query.category_id
            )

            if (
              indexCategoryInChildrenTemp !== undefined &&
          indexCategoryInChildrenTemp >= 0
            ) {
              indexCategoryInParents.value = index
              indexCategoryInChildren.value = indexCategoryInChildrenTemp
            }
          })
        }
        if (indexCategoryInParents.value >= 0) {
          selectedIndex.value = indexCategoryInParents.value
          collectionRef.value.setIndex(indexCategoryInParents.value)
          collectionRef.value.open(true)
        } else {
          /* في حال عدم القدرة على ايجاد التصنيف ضمن التصانيف الموجودة لأنه يمكن أن يكون أدنى الشجرة فلذلك محاولة توليد الشجرة باستخدام  التصانيف المستخدمة في توليد كمبوننت مسار التصنيفات */
          openCategoryChildrenByCategoriesCrumbs()
        }
      } else {
        QueryNavigation(undefined)
      }
    } else {
      /* في حال تم اختيار البنية السطرية */
      indexCategoryInParents.value = categoriesCategoryStyle.value?.findIndex(
        (el) => el.id.toString() === route.query.category_id
      )
      selectedIndex.value = indexCategoryInParents.value
    }
  }
}
/* توليد شجرة التصنيفات باستخدام مسار التصنيفات */
function openCategoryChildrenByCategoriesCrumbs() {
  const categoriesCrumbs = pathCrumps
  categoriesCategoryStyle.value?.forEach((el, index) => {
    const tempFilterArray = categoriesCrumbs.filter((cat) => cat.id === el.id)
    if (tempFilterArray.length) {
      selectedIndex.value = index
      collectionRef.value!.setIndex(index)
      collectionRef.value!.open(true)
    }
  })
}
</script>
